import { Typography } from "@mui/material";
import Head from "next/head";
import { ReactElement } from "react";
import Layout from "@/interface/layout/Layout";
import NavigationBar from "@/interface/layout/NavigationBar";
import Section from "@/interface/layout/Section";
import TripCardListItem from "@/interface/trip-card/TripCardListItem";
import TripCardList from "@/interface/trip-card/TripCardList";
import TripCard from "@/interface/trip-card/TripCard";
import PageTitle from "@/interface/layout/PageTitle";
import { TRIP_OSTSEE } from "@/infrastructure/fixtures/Trip";

export default function LandingPage(): ReactElement {
  return (
    <Layout>
      <Head>
        <title>Landing page</title>
      </Head>
      <NavigationBar />
      <Section sx={{ backgroundColor: "#F5F5F7" }}>
        <Typography variant="subtitle1" textAlign="center">
          Erlebe einen ganz besonderen Tag inklusive Flug.
        </Typography>
      </Section>
      <Section sx={{ flexGrow: 1 }}>
        <PageTitle>Trips</PageTitle>
        <TripCardList>
          <TripCardListItem>
            <TripCard trip={TRIP_OSTSEE} />
          </TripCardListItem>
          <TripCardListItem>
            <TripCard trip={TRIP_OSTSEE} />
          </TripCardListItem>
          <TripCardListItem>
            <TripCard trip={TRIP_OSTSEE} />
          </TripCardListItem>
        </TripCardList>
      </Section>
    </Layout>
  );
}
