import { Button, ButtonProps } from "@mui/material";
import Link, { LinkProps } from "next/link";
import { ReactElement } from "react";

interface Props extends Omit<ButtonProps, "href" | "component"> {
  href: LinkProps["href"];
  target?: string;
}

export default function LinkButton({
  href,
  target,
  children,
  ...buttonProps
}: Props): ReactElement {
  return (
    <Link href={href} target={target} passHref legacyBehavior>
      <Button {...buttonProps}>{children}</Button>
    </Link>
  );
}
