import Head from "next/head";
import styles from "@/interface/layout/Layout.module.css";
import { ReactNode, ReactElement } from "react";
import Footer from "@/interface/footer/Footer";

interface Props {
  children?: ReactNode;
}

export default function Layout({ children }: Props): ReactElement {
  return (
    <div className={styles.container}>
      <Head>
        <title>Flight Booking</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
      </Head>
      <main className={styles.main}>{children}</main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}
