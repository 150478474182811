import { Grid } from "@mui/material";
import { ReactElement, ReactNode } from "react";

interface TripCardListItemProps {
  children?: ReactNode;
}

export default function TripCardListItem({
  children,
}: TripCardListItemProps): ReactElement {
  return (
    <Grid
      item
      md={6}
      sm={12}
      sx={{ display: "flex", justifyContent: "space-around" }}
    >
      {children}
    </Grid>
  );
}
