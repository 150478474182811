import { Typography } from "@mui/material";
import { ReactNode, ReactElement } from "react";

interface Props {
  children?: ReactNode;
}

export default function PageTitle({ children }: Props): ReactElement {
  return (
    <Typography
      variant="h1"
      component="h1"
      sx={{ marginTop: "2rem", marginBottom: "2rem" }}
    >
      {children}
    </Typography>
  );
}
