import { CardActionArea } from "@mui/material";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { useRouter } from "next/router";
import { ReactElement } from "react";
import LinkButton from "@/interface/buttons/LinkButton";
import { Trip } from "@/interface/trips/Trip";

interface Props {
  trip: Trip;
}

export default function TripCard({ trip }: Props): ReactElement {
  const router = useRouter();

  const detailPagePath = `/trips/${trip.slug}`;

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea
        onClick={(): void => {
          router.push(detailPagePath);
        }}
      >
        <CardMedia
          component="img"
          height="140"
          image={trip.imageUrl}
          alt={trip.name}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Ostsee
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {trip.shortDescription}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <LinkButton href={detailPagePath}>Buchen</LinkButton>
      </CardActions>
    </Card>
  );
}
