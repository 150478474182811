import { ReactElement } from "react";
import Section from "@/interface/layout/Section";
import LinkButton from "@/interface/buttons/LinkButton";

export default function NavigationBar(): ReactElement {
  return (
    <Section
      sx={{
        backgroundColor: "primary.main",
        color: "background.default",
        textAlign: "center",
      }}
    >
      <LinkButton href="/" color="inherit">
        LOGO
      </LinkButton>
    </Section>
  );
}
