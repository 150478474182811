import { nextFriday, nextSaturday } from "date-fns";
import { Trip } from "@/interface/trips/Trip";

const AVAILABLE_DATES = [nextFriday(new Date()), nextSaturday(new Date())];

const START_TIMES = ["10:00", "20:00"];

export const TRIP_OSTSEE: Trip = {
  slug: "ostsee",
  name: "Ostsee",
  imageUrl: "/images/stephan-glauner-QWxcjUzdtdY-unsplash.jpg",
  shortDescription:
    "Starten Sie in Berlin und entdecken Sie die Ostsee in einer ganz besonderen Form.",
  description:
    "Herzlich willkommen zu einer meiner Lieblingstouren in der Gegend!\n" +
    "Wir starten bei gutem Wetter am Flugplatz Strausberg zu einem beeindruckenden Flug über die Seenplatte: Zunächst sehen wir die alte Bundeshauptstadt Bonn aus der Vogelperspektive und fliegen über Rheinbach und die Steinbachtalsperre nach Bad Münstereifel.\n" +
    "Von dort geht es über das Radioteleskop Effelsberg (auch aus der Luft noch beeindruckend groß...) über die Ahr östlich am Aremberg vorbei zum Nürburgring, über dem wir eine Ehrenrunde drehen werden.\n" +
    'Natürlich darf auch ein Abstecher zum höchsten Berg der Eifel ("Hohe Acht") nicht fehlen.\n' +
    "Von dort steuern wir das Kloster Maria Laach am Laacher See an und folgen schließlich dem Rhein in nördlicher Richtung über Linz am Rhein, Unkel und Bad Honnef zum Siebengebirge. Ein schöner Blick auf die Ruine der Burg Drachenfels und den Petersberg mit dem ehemaligen Gästehaus der Bundesrepublik rundet unseren Flug ab, bevor wir sicher wieder in Hangelar landen.\n" +
    "Ich freue mich darauf, dieses Erlebnis mit netten Gästen zu teilen!\n" +
    "\n" +
    'Die Cessna bietet tolle Möglichkeiten zum Filmen und Fotografieren, da die Flügel "höhergehängt" sind.\n' +
    "\n" +
    "MEHR ÜBER MICH ALS PILOT IN MEINEM PROFIL.\n" +
    "Übrigens: Den CO2-Abdruck jedes Fluges kompensiere ich bei myclimate.org. Bis bald!\n" +
    "\n" +
    "TIPPS FÜR EINEN ENTSPANNTEN FLUG\n" +
    "1. Ausgeschlafen und nicht mit leerem oder vollem Magen fliegen.\n" +
    "2. Bequeme Kleidung tragen und Sonnenbrille einpacken.\n" +
    "3. Turbulenzen sind ungefährlich, aber die Bewegungen des Kleinflugzeugs evtl. ungewohnt (z.B. beim Kreisen an einem Ort). Empfindlichen Gästen empfehle ich, 30 Min. vor Abflug eine Tablette gegen Reisekrankheit einzunehmen, z.B. Vomex (gibt's neben Reisekaugummis und Akupressur-Bändern rezeptfrei in der Apotheke).",
  plane: {
    name: "Cessna",
    seats: 4,
    maxGuestCount: 3,
  },
  pilot: {
    name: "Fabian",
    licenseName: "PPL",
    introduction: "Ich fliege seit...",
  },
  route: {
    origin: {
      name: "Berlin Strausberg",
      locationLink: "https://goo.gl/maps/MqKP8C6t5M3XeL8W6",
    },
    destination: {
      name: "Ostsee",
    },
  },
  availability: {
    dates: AVAILABLE_DATES,
    startTimes: START_TIMES,
    durationHours: 8,
  },
};
