import { Grid } from "@mui/material";
import { ReactElement, ReactNode } from "react";

interface TripCardListProps {
  children?: ReactNode;
}

export default function TripCardList({
  children,
}: TripCardListProps): ReactElement {
  return (
    <Grid container spacing={2}>
      {children}
    </Grid>
  );
}
