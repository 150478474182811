import { ReactElement } from "react";
import styles from "@/interface/footer/Footer.module.css";
import { Box, Grid, Typography } from "@mui/material";
import LinkButton from "@/interface/buttons/LinkButton";

export default function Footer(): ReactElement {
  return (
    <Box
      sx={{ color: "background.default", backgroundColor: "primary.main" }}
      className={styles.root}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="body2">© 2022 FLIGHT BOOKING GMBH</Typography>
        <LinkButton href="/impressum" target="_blank" color="inherit">
          Impressum
        </LinkButton>
      </Grid>
    </Box>
  );
}
