import styles from "@/interface/layout/Section.module.css";
import { ReactElement } from "react";
import { Box, BoxProps, Container } from "@mui/material";

type Props = BoxProps;

export default function Section({ children, ...props }: Props): ReactElement {
  return (
    <Box component="section" className={styles.root} {...props}>
      <Container>{children}</Container>
    </Box>
  );
}
